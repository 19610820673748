$(document).ready(function () {
    window.Livewire.hook('message.sent', () => {
        $('[role="loading"]').each(function() {
            if ($(this).hasClass('d-none'))
                $(this).removeClass('d-none');
        });
    });

    window.Livewire.hook('message.processed', () => {
        $('[role="loading"]').each(function() {
            if (!$(this).hasClass('d-none'))
                $(this).addClass('d-none');
        });
    });
});
